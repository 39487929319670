var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "foot-bar" },
    [
      _c(
        "van-tabbar",
        {
          on: { change: _vm.changeHandle },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _c(
            "van-tabbar-item",
            {
              attrs: { name: "1", icon: "home-o" },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function (props) {
                    return [
                      props.active
                        ? _c("img", {
                            attrs: {
                              src: require("../../assets/images/index-a.png"),
                              alt: "",
                            },
                          })
                        : _c("img", {
                            attrs: {
                              src: require("../../assets/images/index.png"),
                              alt: "",
                            },
                          }),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("首页")])]
          ),
          _c(
            "van-tabbar-item",
            {
              attrs: { name: "2", icon: "home-o" },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function (props) {
                    return [
                      props.active
                        ? _c("img", {
                            attrs: {
                              src: require("../../assets/images/my-doctor-a.png"),
                              alt: "",
                            },
                          })
                        : _c("img", {
                            attrs: {
                              src: require("../../assets/images/my-doctor.png"),
                              alt: "",
                            },
                          }),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("我的医生")])]
          ),
          _c(
            "van-tabbar-item",
            {
              attrs: { name: "4", icon: "home-o" },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function (props) {
                    return [
                      props.active
                        ? _c("img", {
                            attrs: {
                              src: require("../../assets/images/mine-a.png"),
                              alt: "",
                            },
                          })
                        : _c("img", {
                            attrs: {
                              src: require("../../assets/images/mine.png"),
                              alt: "",
                            },
                          }),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("我的")])]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }